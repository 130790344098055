/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es6/reflect';


/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
import 'core-js/es7/reflect';


/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

 // (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 // (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 // (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

 /*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
// (window as any).__Zone_enable_cross_context_check = true;

declare global {
  interface Window {
    mozRequestAnimationFrame: any;
    msRequestAnimationFrame: any;
    oRequestAnimationFrame: any;
    mozCancelAnimationFrame: any;
    msCancelAnimationFrame: any;
    oCancelAnimationFrame: any;
  }
}

(window as any).global = window;

(Object as any).compare = function (obj1, obj2) {
  if ((typeof obj1 === 'undefined' && typeof obj2 !== 'undefined') || (typeof obj1 !== 'undefined' && typeof obj2 === 'undefined')) {
    return false;
  }
  // Loop through properties in object 1
  // tslint:disable-next-line:forin
  for (let p in obj1) {
    // Check property exists on both objects
    if ((obj1 && !obj2) || (!obj1 && obj2) || (
      ((typeof obj2[p] !== 'undefined' && obj1.hasOwnProperty(p)) ||
      (typeof obj1[p] !== 'undefined' && obj2.hasOwnProperty(p))) &&
      obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p))
    ) {
      return false;
    }
    switch (typeof (obj1[p])) {
      // Deep compare objects
      case 'object':
        if (!(Object as any).compare(obj1[p], obj2[p])) {
          return false;
        }
        break;
      // Compare function code
      case 'function':
        if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) {
          return false;
        }
        break;
      // Compare values
      default:
        if (obj1[p] !== obj2[p]) {
          return false;
        }
    }
  }

  // Check object 2 for any extra properties
  for (let p in obj2) {
    if (typeof (obj1[p]) === 'undefined' && typeof (obj2[p]) !== 'undefined') {
      return false;
    }
  }
  return true;
};

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */

